import React from "react"
import { Link } from "gatsby"
import { useState } from "react"
import burger from "../assets/images/burger-blue.svg"
import burgerWhite from "../assets/images/burger-white.svg"

const MainNav = ({ menuLinks, path }) => {
  const [toggled, setToggle] = useState(false)
  const [hovered, setHover] = useState(false)

  // toggle main menu
  const triggerToggle = () => {
    setToggle(!toggled)
  }

  // change burger colour on hover
  const triggerHover = () => {
    setHover(!hovered)
  }

  // close main nav menu on hash navigation
  const closeMenu = () => {
    if (window.innerWidth < 768) {
      triggerToggle()
    }
  }

  return (
    <nav className="site-header__nav">
      <button
        className={`site-header__nav__toggle ${toggled ? "toggled" : ""}`}
        onClick={triggerToggle}
      >
        <img
          onMouseEnter={triggerHover}
          onMouseLeave={triggerHover}
          src={hovered ? burger : burgerWhite}
          className="burger"
          aria-hidden="true"
          alt="burger-menu-icon"
        />
      </button>

      <ul 
        className={`site-header__nav__list ${toggled ? "open" : "closed"}`}
        onClick={() => closeMenu()}
      >
        {menuLinks.map(link => (
          <li key={link.name} className="site-header__nav__list-item">
            <Link
              className="site-header__nav__link"
              to={link.link}
            >
              {link.name}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default MainNav
