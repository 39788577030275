import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import MainNav from "./MainNav"
import logoWhiteHorizontal from "../assets/images/lunar-logo-horizontal.svg"
import logoWhiteText from "../assets/images/lunar-logo-text.svg"
import logoWhiteIcon from "../assets/images/lunar-logo-icon.svg"

const Header = ({ siteTitle, menuLinks, isHomepage = false, path }) => {
  // Scroll animation
  if (typeof window !== "undefined" && isHomepage) {
    const bodyStyle = document.body.style
    let setScroll = () => {
      bodyStyle.setProperty(
        "--scroll",
        window.pageYOffset / (document.body.offsetHeight - window.innerHeight)
      )
    }
    window.addEventListener("scroll", setScroll, false)
    setScroll()
  }

  return (
    <header
      id="header"
      className={`container site-header ${
        isHomepage ? "site-header--homepage" : ""
      }`}
    >
      <Link className={"site-header__logo"} to="/">
        <img
          className={"site-header__logo--horizontal"}
          src={logoWhiteHorizontal}
          alt={siteTitle}
        />

        {isHomepage && (
          <div className="site-header__logo--animated">
            <img
              className={"site-header__logo--icon"}
              src={logoWhiteIcon}
              alt="Moon Icon"
            />
            <img
              className={"site-header__logo--text"}
              src={logoWhiteText}
              alt={siteTitle}
            />
          </div>
        )}
      </Link>

      <MainNav menuLinks={menuLinks} path={path} />
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  menuLinks: PropTypes.array,
}

Header.defaultProps = {
  siteTitle: ``,
  menuLinks: [],
}

export default Header
