import React from "react"

// Pass in two blocks of content. If one block contains multiple text elements, wrap it in a BasicText component

// Be wary of indentation - it can prevent proper parsing of the markdown

const TwoColumnBlock = ({ children }) => (
  <section className="two-column">{children}</section>
)

export default TwoColumnBlock
