import React from "react"
import { Link } from "gatsby"
import backToTop from "../assets/images/back-to-top-arrow.svg"
import lunarLogoDark from "../assets/images/lunar-logo-dark-vertical.svg"
import cyberEssentials from "../assets/images/cyber-essentials-logo-v2.png"
import linkedInLogo from "../assets/images/li-In-bug.png"

const Footer = ({ address, siteTitle, menuLinks }) => {
  return (
    <footer className="container site-footer">
      <img className="site-footer__logo" alt={siteTitle} src={lunarLogoDark} />

      <div className="site-footer__info">
        <ul className={"site-footer__nav__list"}>
          {menuLinks.map(link => (
            <li key={link.name} className="site-footer__nav__list-item">
              <Link className="site-footer__link" to={link.link}>
                {link.name}
              </Link>
            </li>
          ))}
          <Link to="/privacy-policy" className="site-footer__link">
            Privacy Policy
          </Link>
        </ul>

        <p className="site-footer__address">{address}</p>

        <p>
          &copy; {new Date().getFullYear()} Lunar Web Development Limited:
          Company Number 11202954
        </p>
      </div>

      <div className="site-footer__sub-logos">
        {/* <img
          className="site-footer__cyber-logo"
          alt={"Cyber Essentials Certification Mark"}
          src={cyberEssentials}
        /> */}
        <div className="site-footer__cyber-logo">
          <iframe
            src="https://registry.blockmarktech.com/certificates/5571f4b7-2b77-414a-b5e5-a0302a16ff59/widget/?non_padded=t&tooltip_position=corner&theme=transparent"
            styles="border: none; height:160px; width:132px;"
          ></iframe>
        </div>

        <a
          href="https://uk.linkedin.com/company/lunar-web-development-ltd"
          className="site-footer__social"
          title="View our Linkedin profile"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={linkedInLogo} alt={"Link to Lunar LinkedIn page"} />
        </a>

        <a
          href="https://ecologi.com/lunar?r=610420db9c3198238d087119"
          target="_blank"
          rel="noopener noreferrer"
          title="View our Ecologi profile"
          className="site-footer__ecologi"
        >
          <img
            alt="We offset our carbon footprint via Ecologi"
            src="https://ecologi-assets.imgix.net/badges/climate-positive-workforce-white-landscape.png"
          />
        </a>
      </div>

      <a href="#top" className="back-to-top">
        <img src={backToTop} alt={"back to top arrow"} />
        Back to top
      </a>
    </footer>
  )
}

export default Footer
